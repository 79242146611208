import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    newsList:[
      {
        id:1,
        title:'灵鹿获海伦堡控股近千万战略投资',
        msg:'近日,灵鹿已与海伦堡控股达成战略合作协议,并成功融资近千万元。灵鹿智能引入中国房地产50强企业海伦堡控股作为战略投资人,海伦堡控股业务版图覆盖珠江三角洲地区、长江三角洲地区、京津冀地区、华西地区及华中地区15个省市45个城市,业态类型涵盖住宅、商业和创意科技园。',
        imgUrl:'https://dev-officalweb-images.obs.cn-east-2.myhuaweicloud.com/images/news/2.png'
      },
      {
        id:3,
        title:'获多家媒体报道，华为云助力灵鹿打造行业领先的智能家居数字化解决方案',
        msg:'近日，灵鹿全屋智能获宁波日报、甬派新闻、钱江晚报、浙里有为等多家知名媒体报道，华为云宁波沃土工场助力浙江灵鹿智能科技有限公司，打造了全国领先的智能家居数字化解决方案。',
        imgUrl:"https://dev-officalweb-images.obs.cn-east-2.myhuaweicloud.com/images/news/3.png"
      },
      {
        id:2,
        title:'灵鹿全屋智能正式发布，邀您共赴理想生活',
        msg:'2022年9月7日，灵鹿智能以“越过山丘•遇见灵鹿”为主题，于浙江宁波举办了灵鹿品牌发布会。除了有来自全国各地的智能家居行业的小伙伴外，华为云、海伦堡控股、信科集团、中航建、中天美好生活服务集团、约克（中国）、莱福德科技、向往智能、凯越集团、看见未来家、卡巴尔电气、亚翔电子等灵鹿挚友也受邀前来参加了本次灵鹿品牌发布会。',
        imgUrl:'https://dev-officalweb-images.obs.cn-east-2.myhuaweicloud.com/images/news/newslist3.png'
      }
      
    ]
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
