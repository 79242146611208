<template>
  <div id="footers">
    <div class="all">
      <div class="ul">
        <ul>
          <li v-for="(item, a) in toplist" :key="a">
            <router-link :to="item.link">{{ item.name }}</router-link>
          </li>
        </ul>
      </div>

      <div class="content-box">
        <div class="room">
          <div class="content-item" v-for="(item, b) in oneList" :key="b">
            <ul v-for="(title, ia) in item.titleList" :key="ia">
              <li @click="handleRouter(title.link)">{{ title.name }}</li>
            </ul>
          </div>
        </div>


        <!-- <div class="room">
          <div class="content-item" v-for="(item, d) in thrList" :key="d">
            <ul v-for="(title, ic) in item.titleList" :key="ic">
              <li @click="handleProduct(title)">{{ title.name }}</li>
            </ul>
          </div>
        </div> -->

        <div class="room">
          <div class="content-item" v-for="(item, e) in thrList" :key="e">
            <ul v-for="(title, id) in item.titleList" :key="id">
              <li @click="handleSolution(title)">{{ title.name }}</li>
            </ul>
          </div>
          <div class="content-item-right">
            <ul v-for="(cTitle, cj) in thrList2[0].titleList" :key="cj">
              <li @click="handleSolution(cTitle)">{{ cTitle.name }}</li>
            </ul>
          </div>
        </div>

      </div>

      <div class="other-box">
        <div class="contact">
          <ul>
            <li>
              <div class="contanct-title">4008 168 720</div>
              <span>7x24小时服务热线</span>
            </li>
            <li>
              <div class="contanct-title">宁波前图物联科技有限公司</div>
              <a href="http://beian.miit.gov.cn">浙ICP备2023023601号</a>
            </li>
            <li>
              <div class="account">
                <div>
                  <img src="../../assets/images/QRCode_Android.png" alt="" />
                  <div class="app_title">安卓</div>
                </div>
                <div>
                  <img src="../../assets/images/QRCode_iOS.png" alt="" />
                  <div class="app_title">iOS</div>
                </div>
                <div class="msg">
                  <div class="title">{{ appload.title }}</div>
                  <div class="more">{{ appload.more }}</div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <confirmModal :dialogVisible="modalShow"></confirmModal>
  </div>
</template>

<script>
import imgsUrl from "../../utils/imgAddress";
import confirmModal from '@/components/confirm-modal.vue'

export default {
  components: {
    confirmModal
  },
  data() {
    return {
      modalShow: false,
      toplist: [
        { name: "关于我们", link: "" },
        { name: "产品中心", link: "" },
      ],

      oneList: [
        {
          titleList: [
            { name: "公司介绍", link: "/about" },
            { name: "联系我们", link: "/cooperate" },
          ]
        }
      ],

      thrList: [
        {
          titleList: [
            { name: "智慧主控中心", link: "/product", md: 'smart' },
            { name: "灯光控制模块", link: "/product", md: 'lighting' },
            { name: "协议转换模块", link: "/product", md: 'protocol' }
          ]
        }
      ],

      thrList2: [
        {
          titleList: [
          { name: "传感检测器", link: "/product", md: 'sensing' },
          { name: "智能开关", link: "/product", md: 'panel' }
          ]
        }
      ],
      forList: [
        {
          titleList: [
            { name: "暖通控制", link: "fifth", },
            { name: "智能遮阳", link: "fourth" },
            { name: "智能安防", link: "third" },
            { name: "智能照明", link: "second" },
            { name: "智能调光", link: "first" }
          ]
        }
      ],
      forList2: [
        {
          titleList: [
            { name: "中控管理", link: "tenth" },
            { name: "语音控制", link: "ninth" },
            { name: "环境监测", link: "eighth" },
            { name: "能源管理", link: "seventh" },
            { name: "智能影音", link: "sixth" }
          ]
        }
      ],
      contactList: [
        { title: "4008 168 720", msg: "7x24小时服务热线" },
        { title: "浙江灵鹿智能科技有限公司", msg: "浙ICP备2022000952号" }
      ],
      account: {
        title: "关注微信公众号",
        more: "了解更多灵鹿信息",
        codeUrl: imgsUrl.code.code
      },
      appload: {
        title: "前图智能APP",
        more: "手机扫码下载",
        codeUrl: imgsUrl.code.appload
      }
    };
  },
  methods: {
    handleRouter(link) {
      if (link == 'free') {
        this.modalShow = true;
      }
      else if (link == this.$route.path) {
        this.$router.push(
          {
            path: `${link}`,
            query: {
              sj: Math.random()
            }
          }
        );

      }
      else {
        this.$router.push(link);

      }
    },
    // 跳转至产品中心锚点
    handleProduct(val) {
      // 采用Math随机数，避免跳转到相同页面，导致router的缓存机制不给刷新。
      this.$router.push(
        {
          path: `${val.link}`,
          query: {
            md: val.md,
            sj: Math.random()
          }
        }
      );
    },
    // 跳转至解决方案
    handleSolution(val) {
      this.$router.push(
        {
          path: '/product',
          query: {
            md: val.link,
            sj: Math.random()
          }
        }
      );
    },
    handleModal() {
      this.modalShow = false;
    },
  }
};
</script>

<style lang="scss" scoped>
#footers {
  width: 100%;
  background: rgba(22, 22, 22, 1);

  .all {
    width: 80%;
    padding: 78px 10% 54px 10%;
    letter-spacing: 1px;

    .ul {
      height: 50px;
      width: 100%;
      display: flex;
      justify-content: space-around;
      align-items: center;
      padding: auto 0;

      ul {
        width: 100%;
        display: flex;
        justify-content: space-around;

        li {
          width: 120px;

          a {
            font-weight: bold;
            font-size: 16px;
            color: #fff;
          }
        }
      }
    }

    .content-box {
      display: flex;
      justify-content: space-around;

      .room {
        width: 120px;
        margin: 20px 0px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .content-item {
          ul {
            li {
              width: 110px;
              font-size: 14px;
              font-weight: bold;
              color: #808080;
              margin-bottom: 16px;
              cursor: pointer;
            }
          }
        }

        .content-item-right {

          // margin-left: 40px;
          ul {
            li {
              width: 80px;
              font-size: 14px;
              font-weight: bold;
              color: #808080;
              margin-bottom: 16px;
              cursor: pointer;
            }
          }
        }
      }
    }

    .other-box {
      padding-top: 48px;
      margin-top: 48px;
      border-top: 1px solid rgba(216, 216, 216, 0.2);
      color: #d7d7d7;
      display: flex;
      justify-content: space-between;

      .contact {
        width: 100%;

        ul {
          display: flex;
          justify-content: space-between;

          li {
            .account {
              display: flex;

              img {
                width: 69px;
                height: 69px;
                margin-right: 29px;
              }
              .app_title{
                width: 69px;
                text-align: center;
              }

              .msg {
                .title {
                  font-size: 16px;
                  margin-bottom: 12px;
                  width: 100%;
                }

                .more {
                  font-size: 16px;
                  color: #808080;
                }
              }
            }

            .contanct-title {
              font-size: 16px;
              // font-weight: bold;
              margin-bottom: 12px;


            }

            span {
              color: #808080;
              font-size: 16px;
              letter-spacing: 0px;
            }
          }
        }
      }


    }
  }
}
</style>
