import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import router from './router'
import store from './store'
// main.js中引入外部字体
import './assets/fonts/fonts.css'
import axios from 'axios'
Vue.prototype.$http=axios
Vue.use(ElementUI);
Vue.config.productionTip = false

import Video from 'video.js'
import 'video.js/dist/video-js.css'

Vue.prototype.$video = Video

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

//跳转至顶部
router.afterEach(() => {undefined
  window.scrollTo(0,0);
})
