<template>
  <div id="navbox" :style="style">
    <div id="navBar" :class="{ 'nav-black': twoListShow }">
      <div id="nav" @mouseenter="handleMouseEnter" @mouseleave="handleMouseLeaave">
        <div class="logo">
          <img src="../../assets/images/logo-hover.png" alt="" />
        </div>
        <ul>
          <li v-for="(item, index) in list" :class="{ activeMenu: activeIndex == index }" :key="index"
            @click="handleRouter(index, item.link)">
            {{ item.name }}
            <!-- <router-link :to="item.link">{{ item.name }}</router-link> -->
          </li>
        </ul>
      </div>

    </div>
    <div class="two-list" v-show="twoListShow">
      <ul>
        <li v-for="(item, index) in twoList" :key="index" @click="handleRouter(index, item.link)">
          {{ item.name }}
        </li>
      </ul>
    </div>
    <confirmModal :dialogVisible="modalShow"></confirmModal>
  </div>
</template>
<script>
import confirmModal from '@/components/confirm-modal.vue'
export default {
  data() {
    return {
      logoShow: true,
      activeIndex: 0,
      twoListShow: false,
      list: [
        { name: "首页", link: "/" },
        { name: "关于前图", link: "/about" },
        { name: "产品中心", link: "/product" },
        { name: "联系我们", link: "/cooperate" }
      ],
      twoList: [
        { name: "免费设计", link: "free" },
        { name: "商务合作", link: "/cooperate" },
        { name: "加入我们", link: "/jion" },
      ],
      modalShow: false,
      style: {}
    };
  },
  components: {
    confirmModal
  },
  mounted() {
    window.addEventListener("scroll", this.windowScroll);
  },
  methods: {
    // 导航栏router 点击样式切换
    handleRouter(index, link) {
      this.activeIndex = index;
      if (link == 'free') {
        this.modalShow = true;
      } else if (this.$route.path != link && link) {
        this.$router.push(link);
        this.twoListShow = false;
      }
      if (link == "/cooperate" || link == "/jion") {
        this.activeIndex = 3
      }
    },
    handleModal() {
      this.modalShow = false;
    },
    // 浏览器滚动顶部导航栏背景色切换
    windowScroll() {
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      if (scrollTop > 49) {
        this.style = { background: `rgba(10, 10, 10, 0.85)` };
      } else {
        this.style = { background: `rgba(0, 0, 0, 0)` };
      }
    },
    // 二级导航栏显示
    listShow(index) {
      if (index == this.list.length - 1) {
        this.twoListShow = true;
      } else {
        this.twoListShow = false;
      }
    },
    handleMouseEnter() {
      this.style = { background: `rgba(10, 10, 10, 0.85)` };
    },
    handleMouseLeaave() {
      this.style = { background: `rgba(0, 0, 0, 0)` };
    },
    
  }
};
</script>
<style scoped lang="scss">
.nav-black {
  background: #000 !important;
}

#navbox {
  width: 100%;
  transition: all 1.5s;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  .two-list {
    background: rgba(17, 17, 17, .6);
    height: 75px;
    width: 100%;

    ul {
      display: flex;
      justify-content: center;
      align-items: center;

      li {
        cursor: pointer;
        line-height: 75px;
        color: #AEAEAE;
        font-size: 16px;
        padding: 0 36px;
      }

      li:hover {
        color: #3E86F7;
      }
    }
  }

  #navBar {
    width: 100%;
    display: flex;
    justify-content: center;

    #nav {
      width: 80%;
      margin-top: 18px;
      height: 52px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: auto 0;

      .logo {
        img {
          width: 52px;
          height: 52px;
          cursor: pointer;
        }
      }

      ul {
        width: 80%;
        display: flex;
        // justify-content: space-between;

        li {
          text-align: center;
          min-width: 120px;
          margin-right: 100px;
          cursor: pointer;
          font-size: 18px;
          padding: 15px 0px;
          color: #fff;
          border-bottom: 2px solid rgba($color: #000000, $alpha: 0);
          letter-spacing: 2px;
          transition: all 0.7s;
          // a {
          //   font-size: 16px;
          //   color: #fff;
          // }

          // a:hover {
          //   color: rgba(62, 134, 247, 1);

          // }
        }

        li:hover,
        .activeMenu {
          transition: all 0.4s;
          color: rgba(62, 134, 247, 1);
          border-bottom: 2px solid rgba(62, 134, 247, 1);
        }
      }
    }


  }

}
</style>
