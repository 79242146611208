<template>
    <el-dialog title="免费预约设计专属定制的全屋智能方案" :visible.sync="dialogVisible" :before-close="handleClose" width="580px"
        append-to-body class="confirm-modal" top="8vh" :style="{ '--background': modalBg }">
        <div class="main">
            <div class="item-box">
                <div class="item-title">您怎么称呼：</div>
                <el-input class="item-input" v-model="form.name" placeholder="请输入您的姓名"></el-input>
            </div>
            <div class="item-box">
                <div class="item-title">您所在城市：</div>
                <div class="item-select">
                    <el-select v-model="form.province" placeholder="请选择省份" @change="provinceChange">
                        <el-option v-for="item in provinceList" :key="item.value" :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                    <el-select v-model="form.city" placeholder="请选择城市" @change="cityChange">
                        <el-option v-for="item in cityList" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                    <el-select v-model="form.district" placeholder="请选择区县">
                        <el-option v-for="item in districtList" :key="item.value" :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </div>
            </div>
            <div class="item-box">
                <div class="item-title">您的手机号：</div>
                <el-input class="item-input" v-model="form.phone" placeholder="请输入您的手机号"></el-input>
            </div>
            <div class="item-box">
                <div class="item-title">请选择贵府面积：</div>
                <div class="item-check">
                    <div class="check-item" @click="changeCheck(item.value, index)" v-for="(item, index) in list"
                        :key="index" :class="{ 'active': item.checked }">
                        <span v-html="item.name"></span>
                    </div>
                </div>
            </div>
            <div class="item-btn">
                <el-button type="primary" @click="handleConfirm">免费预约设计</el-button>
            </div>
        </div>
    </el-dialog>
</template>
<script>
import imgsUrl from '../utils/imgAddress'
import Address from '../utils/address.json'

export default {
    props: {
        dialogVisible: {
            type: Boolean,
            default: false

        }
    },
   
    data() {
        return {
            modalBg: `url('${imgsUrl.bannerMd.modalBg}')`,
            list: [
                { name: '80m<sup>2</sup>以下', checked: false, value: 1 },
                { name: '80-120m<sup>2</sup>', checked: false, value: 2 },
                { name: '120-200m<sup>2</sup>', checked: false, value: 3 },
                { name: '200-300m<sup>2</sup>', checked: false, value: 4 },
                { name: '300-500m<sup>2</sup>', checked: false, value: 5 },
                { name: '500m<sup>2</sup>以上', checked: false, value: 6 },
            ],
            provinceList: Address,
            cityList: [],
            districtList: [],
            form: {
                name: '',
                phone: '',
                province: '',
                city: '',
                district: '',
                area: ''
            },
        };
    },
    mounted() {
    },
    methods: {
        provinceChange(name) {
            this.cityList = [];
            this.form.city = this.form.district = '';
            let arr = this.provinceList.filter(item => { return item.label == name })
            this.cityList = arr[0].children
        },
        cityChange(name) {
            this.districtList = [];
            this.form.district = '';
            let arr = this.cityList.filter(item => { return item.label == name })
            this.districtList = arr[0].children
        },

        handleModal() {
            this.$parent.handleModal();
        },
        changeCheck(value, index) {
            this.list.forEach(item => {
                item.checked = false;
            })
            this.list[index].checked = true;
            this.form.area = value;
        },
        handleClose() {
            this.$parent.handleModal();

        },
        handleConfirm() {
            for (let i in this.form) {
                if (!this.form[i]) {
                    this.$message.warning('请完善信息！')
                    return false;
                }
            }
            let reg = /^((13[0-9])|(14[5|7])|(15([0-3]|[5-9]))|(18[0,5-9]))\d{8}$/;
            if (!reg.test(this.form.phone)) {
                this.$message.warning('请输入正确的手机号！')
                return false;
            } else {
                console.log('this.for', this.form)
                this.$http({
                    method: 'post',
                    url: 'https://dev-lmps-api.lingluzn.com/lmps/system/user/addclient',
                    data: {
                        "id": "",
                        "sign": "",
                        "timestamp": 0,
                        "v": "",
                        "data": this.form
                    }
                }).then(() => {
                    for (let i in this.form) {
                        this.form[i] = ''
                    }
                    this.$message.success('提交成功，我们将24小时内联系您')
                    this.$parent.handleModal();
                })
            }
        },


    }
};
</script>
<style lang="scss">
.confirm-modal {
    .el-dialog {
        border-radius: 0;
        background: var(--background);
        background-size: 100% 100%;
    }

    .el-dialog__header {
        font-weight: bold!important;
        text-align: center!important;
        font-size: 26px!important;
    }
    .el-dialog__title{
        font-size: 26px!important;

    }

}
</style>
<style lang="scss" scoped >
.main {
    width: 100%;

    .item-box {
        margin-bottom: 16px;

        .item-title {
            font-size: 18px;
            color: #1F1D1D;
            margin-bottom: 15px;
        }

        ::v-deep .el-input__inner {
            height: 66px;
            font-size: 18px;
        }

        .item-check {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            

            .check-item {
                padding: 17px 15px;
                background: #F5F5F7;
                border-radius: 4px;
                cursor: pointer;
                margin-bottom: 20px;
                width: 26%;
                text-align: center;
            }

            .active {
                background: #3E86F7;
                color: #F5F5F7;
            }
        }

        .item-select {
            display: flex;
            justify-content: space-between;

            ::v-deep .el-select {
                width: 30%;
            }
        }

    }

    .item-btn {
        width: 100%;
        margin-bottom: 47px;

        ::v-deep .el-button {
            font-weight: bold;
            font-size: 20px;
            width: 100%;
            height: 66px;
            margin-top: 42px;
            background: #3E86F7;
            border-radius: 4px;
            border: #3E86F7 solid 1px;
            letter-spacing: 10px;
            
        }
    }
}
</style>