let domain = 'https://dev-officalweb-images.obs.cn-east-2.myhuaweicloud.com/images/'
const imgsUrl ={
    bannerMd:{
        banner: domain +'md/banner/pc_home_banner.png',
        productBanner:domain +'md/banner/product_banner.png',
        abdoutBanner:domain + 'md/banner/abdout_banner.png',
        joinBanner:domain + 'md/banner/join_banner.png',
        solutionBanner:domain + 'md/banner/solution_banner.png',
        cooperateBanner:domain + 'md/banner/cooperate_banner.png',
        newsBanner:domain + 'md/banner/news_center.png',
        codeBg:domain + 'md/banner/code_bg.png',
        modalBg:domain + 'md/banner/modal-bg.png',
        
    },
    solutionMd:{
        solutionPic: domain + 'md/solution/solution_picture.png'
    },
    code:{
        code: domain + 'md/code/code.png',
        appload: domain + 'md/code/appload.png',
        zmxCode: domain + 'md/code/zmx_code.png'
    },
    productMd:{
        H20:  domain+'md/prodcut/H20@2x.png',
        L2DALI: domain+'md/prodcut/L2DALI@2x.png',
        L4DC: domain+'md/prodcut/L4DC@2x.png',
        L8C: domain+'md/prodcut/L8C@2x.png',
        L8DC: domain+'md/prodcut/L8DC@2x.png',
        L1CB: domain+'md/prodcut/L1CB@2x.png',
        T315: domain+'md/prodcut/T-315@2x.png',
        T433: domain+'md/prodcut/T-433@2x.png',
        TAC: domain+'md/prodcut/T-AC@2x.png',
        TEC: domain+'md/prodcut/T-EC@2x.png',
        TEX: domain+'md/prodcut/T-EX@2x.png',
        TFH: domain+'md/prodcut/T-FH@2x.png',
        TS3: domain +'md/prodcut/T-S3@2x.png',
        TV: domain +'md/prodcut/T-V@2x.png',
        TVEN: domain+'md/prodcut/T-VEN@2x.png',
        S20: domain+'md/prodcut/S20@2x.png',
        S30: domain+'md/prodcut/S30@2x.png',
        gufajin_4key: domain+'md/prodcut/gufajin_4key.png',
        gufajin_8key: domain+'md/prodcut/gufajin_8key.png',
        jiyinghui_4key: domain+'md/prodcut/jiyinghui_4key.png',
        jiyinghui_8key: domain+'md/prodcut/jiyinghui_8key.png',
        shiguangyin_4key: domain+'md/prodcut/shiguangyin_4key.png',
        shiguangyin_8key: domain+'md/prodcut/shiguangyin_8key.png',
        yongyehei_4key: domain+'md/prodcut/yongyehei_4key.png',
        yongyehei_8key: domain+'md/prodcut/yongyehei_8key.png',
        jiangzaoyin_3key:domain+'md/prodcut/jiangzaoyin_3key.png',
        jiangzaobai_3key:domain+'md/prodcut/jiangzaobai_3key.png',
        jiangzaojin_3key:domain+'md/prodcut/jiangzaojin_3key.png',
        jiangzaohei_3key:domain+'md/prodcut/jiangzaohei_3key.png',
        S408RTy:domain+'md/prodcut/S-408R-ty.png',
        SED:domain+'md/prodcut/S-ED.png',
        SHDC:domain+'md/prodcut/S-HDC.png',
        xyBig:domain+'md/prodcut/xy-big.png',
        xy_argent_4:domain+'md/prodcut/xy_argent_4.png',
        xy_argent_8:domain+'md/prodcut/xy_argent_8.png',
        xy_black_4:domain+'md/prodcut/xy_black_4.png',
        xy_black_8:domain+'md/prodcut/xy_black_8.png',
        xy_golden_4:domain+'md/prodcut/xy_golden_4.png',
        xy_golden_8:domain+'md/prodcut/xy_golden_8.png',
        xy_white_4:domain+'md/prodcut/xy_white_4.png',
        xy_white_8:domain+'md/prodcut/xy_white_8.png',
        TSW:domain+'md/prodcut/T-SW.png',
        xh_mb:domain+'md/prodcut/xh-mb.png',
        xy_wkp:domain+'md/prodcut/xy-wkp.png',
    },
    programmeMd:{
        
    },
    video:{
        home_banner: domain + 'video/home_banner.mp4',
        aboutVideo:domain + 'video/about_video.mp4',
        xuanchuan:domain + 'video/xuanchuan.mp4',
        lingluapp:domain + 'video/lingluapp.mp4',
        appfm:domain+'video/appfm.png',
        homefm:domain+'video/homefm.png',
    },
    fourSupport:{
        ztzc: domain + 'sm/ztzc.png',
        pxzc: domain + 'sm/pxzc.png',
        sczc: domain + 'sm/sczc.png',
        xmzc: domain + 'sm/xmzc.png'
    }
}

export default imgsUrl