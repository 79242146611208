import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // },
  {
    path: '/about',
    name: 'About',
    component: () => import( '../views/About.vue')
  },
  {
    path: '/space',
    name: 'Space',
    component: () => import( '../views/Space.vue')
  },
  {
    path: '/programme',
    name: 'Programme',
    component: () => import( '../views/Programme.vue')
  },

  {
    path: '/product',
    name: 'Product',
    component: () => import( '../views/Product.vue')
  },
  {
    path: '/attractinvestment',
    name: 'AttractInvestment',
    component: () => import( '../views/AttractInvestment.vue')
  },
  {
    path: '/jion',
    name: 'Jion',
    component: () => import( '../views/Jion.vue')
  },
  {
    path: '/newscenter',
    name: 'newscenter',
    component: () => import( '../views/NewsCenter.vue')
  },
  {
    path: '/newscentercontent',
    name: 'newscentercontent',
    component: () => import( '../views/NewsCenterContent.vue')
  },
  {
    path: '/cooperate',
    name: 'cooperate',
    component: () => import( '../views/Cooperate.vue')
  }
  
  
]

const router = new VueRouter({
  routes
})

export default router
